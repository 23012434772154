<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent">
    </TablePage>
  </div>
</template>
<script>
import TablePage from '@/components/tablePage' //表格页面组件
import {
  saleYoYList,
  saleYoYListSummary
} from '@/api/decision/businessReport/saleYoY' //销售同比分析
export default {
  name: 'saleYoY',
  components: {
    TablePage
  },
  data () {
    return {
      options: {
        loading: false,
        pagination: {
          total: 0,
          page: 1,
          size: 15
        },
        title: '销售同比分析',
        listNo: true, // 序号
        //搜索
        search: [
          {
            label: '日期',
            type: 'datetimerange',
            model: '',
            noReset: true,
            filterFnc: ([a, b] = []) => ({
              beginBillDate: a,
              endBillDate: b
            })
          },
          {
            keyIndex: 0,
            format: 'datetimerange',
            type: 'timeRange',
            defaultValue: 'day',
            data: ['day', 'yesterday', 'week', 'lastWeek', 'month', 'lastMonth']
          },
          {
            type: 'filters',
            model: '',
            filters: [
              { filter: 'query', label: '全部' },
              { filter: 'shopNos', label: '门店编码' },
              { filter: 'shopNames', label: '门店名称' },
              { filter: 'categoryNos', label: '类别编码' },
              { filter: 'categoryNames', label: '类别名称' },
              { filter: 'vipLevelNos', label: '级别编码' },
              { filter: 'vipLevelNames', label: '级别名称' }
            ]
          },
          this.$select({
            key: 'listShop',
            option: {
              filter: 'shopIds',
              seniorSearch: true,
              hide: () =>
                this.options?.tabColumnType == 'shopSales' ||
                this.options?.tabColumnType == 'passengerFlow',
              option: {
                multiple: true,
                buttons: [
                  {
                    type: 'more',
                    option: {
                      title: '选择门店',
                      width: 1250,
                      type: 'TreeAndTable',
                      formData: this.$dialog({ key: 'shop' })
                    }
                  }
                ]
              }
            }
          }),
          this.$select({
            key: 'listCategory',
            option: {
              filter: 'categoryIds',
              seniorSearch: true,
              hide: () => this.options?.tabColumnType == 'categorySales',
              option: {
                multiple: true,
                buttons: [
                  {
                    type: 'more',
                    option: {
                      title: '选择商品类别',
                      width: 1250,
                      type: 'TreeAndTable',
                      formData: this.$dialog({ key: 'category' })
                    }
                  }
                ]
              }
            }
          }),
          this.$select({
            key: 'viplevel',
            option: {
              filter: 'vipLevelIds',
              seniorSearch: true,
              hide: () => this.options?.tabColumnType == 'vipLevel',
              option: {
                multiple: true,
                buttons: [
                  {
                    type: 'more',
                    option: {
                      title: '选择会员级别',
                      width: 1250,
                      type: 'TreeAndTable',
                      formData: this.$dialog({ key: 'vipLevel' })
                    }
                  }
                ]
              }
            }
          }),
          { type: 'button', tip: '查询', btnType: 'primary', click: 'search' },
          {
            type: 'button',
            tip: '高级查询',
            btnType: 'primary',
            click: 'seniorSearch'
          },
          { type: 'button', tip: '重置', click: 'reset' }
        ],
        tabColumnType: 'shopSales',
        tabsColumns: [
          {
            title: '门店销售同比分析',
            type: 'shopSales',
            getListApi: saleYoYList,
            // getSummaryApi: saleYoYListSummary,
            defaultBody: { summaryType: 1 },
            exportOption: {
              fastExportUrl:
                '/api/system/shop/strategy/saleYOYAnalysis/listExport',
              exportName: '门店销售同比分析'
            },
            //表格
            columns: [
              {
                prop: 'shopNo',
                label: '门店编码',
                minWidth: 150,
                align: 'center'
              },
              {
                prop: 'shopName',
                label: '门店名称',
                minWidth: 120,
                align: 'center'
              },
              {
                prop: 'thisPeriodSaleMoney',
                label: '本期销售额',
                minWidth: 120,
                align: 'center',
                sortable: true
              },
              {
                prop: 'upperPeriodSaleMoney',
                label: '上期销售额',
                minWidth: 120,
                align: 'center',
                sortable: true
              },
              {
                prop: 'saleYoyGrowthRate',
                label: '同比增长率(%)',
                minWidth: 110,
                align: 'center',
                sortable: true
              },
              {
                prop: 'saleProportion',
                label: '销售占比(%)',
                minWidth: 100,
                align: 'center',
                sortable: true
              },
              {
                prop: 'thisGain',
                label: '本期毛利',
                minWidth: 100,
                align: 'center',
                sortable: true
              },
              {
                prop: 'upperGain',
                label: '上期毛利',
                minWidth: 100,
                align: 'center',
                sortable: true
              },
              {
                prop: 'gainYoyGrowthRate',
                label: '毛利同比增长率(%)',
                minWidth: 140,
                align: 'center',
                sortable: true
              },
              {
                prop: 'gainSaleProportion',
                label: '毛利销售占比(%)',
                minWidth: 135,
                align: 'center',
                sortable: true
              }
            ],
            summary: [
              'thisPeriodSaleMoney',
              'upperPeriodSaleMoney',
              'thisGain',
              'upperGain'
            ]
          },
          {
            title: '类别销售同比分析',
            type: 'categorySales',
            getListApi: saleYoYList,
            // getSummaryApi: saleYoYListSummary,
            defaultBody: { summaryType: 2 },
            exportOption: {
              fastExportUrl:
                '/api/system/shop/strategy/saleYOYAnalysis/listExport',
              exportName: '类别销售同比分析'
            },
            //表格
            columns: [
              {
                prop: 'categoryNo',
                label: '类别编码',
                minWidth: 150,
                align: 'center'
              },
              {
                prop: 'categoryName',
                label: '类别名称',
                minWidth: 120,
                align: 'center'
              },
              {
                prop: 'thisPeriodSaleMoney',
                label: '本期销售额',
                minWidth: 120,
                align: 'center',
                sortable: true
              },
              {
                prop: 'upperPeriodSaleMoney',
                label: '上期销售额',
                minWidth: 120,
                align: 'center',
                sortable: true
              },
              {
                prop: 'saleYoyGrowthRate',
                label: '同比增长率(%)',
                minWidth: 110,
                align: 'center',
                sortable: true
              },
              {
                prop: 'saleProportion',
                label: '销售占比(%)',
                minWidth: 100,
                align: 'center',
                sortable: true
              },
              {
                prop: 'thisGain',
                label: '本期毛利',
                minWidth: 100,
                align: 'center',
                sortable: true
              },
              {
                prop: 'upperGain',
                label: '上期毛利',
                minWidth: 100,
                align: 'center',
                sortable: true
              },
              {
                prop: 'gainYoyGrowthRate',
                label: '毛利同比增长率(%)',
                minWidth: 140,
                align: 'center',
                sortable: true
              },
              {
                prop: 'gainSaleProportion',
                label: '毛利销售占比(%)',
                minWidth: 135,
                align: 'center',
                sortable: true
              }
            ],
            summary: [
              'thisPeriodSaleMoney',
              'upperPeriodSaleMoney',
              'thisGain',
              'upperGain'
            ]
          },
          {
            title: '客流量同比',
            type: 'passengerFlow',
            getListApi: saleYoYList,
            // getSummaryApi: saleYoYListSummary,
            defaultBody: { summaryType: 3 },
            exportOption: {
              fastExportUrl:
                '/api/system/shop/strategy/saleYOYAnalysis/listExport',
              exportName: '客流量同比'
            },
            //表格
            columns: [
              {
                prop: 'shopNo',
                label: '门店编码',
                minWidth: 150,
                align: 'center'
              },
              {
                prop: 'shopName',
                label: '门店名称',
                minWidth: 150,
                align: 'center'
              },
              {
                prop: 'thisPassengerFlow',
                label: '本期客流量',
                minWidth: 110,
                align: 'center',
                sortable: true
              },
              {
                prop: 'upperPassengerFlow',
                label: '上期客流量',
                minWidth: 110,
                align: 'center',
                sortable: true
              },
              {
                prop: 'passengerFlowYoyGrowth',
                label: '同比增长',
                minWidth: 110,
                align: 'center',
                sortable: true
              },
              {
                prop: 'passengerFlowYoyGrowthRate',
                label: '同比增长率(%)',
                minWidth: 130,
                align: 'center',
                sortable: true
              },
              {
                prop: 'thisSaleQty',
                label: '本期销售数量',
                minWidth: 130,
                align: 'center',
                sortable: true
              },
              {
                prop: 'upperSaleQty',
                label: '上期销售数量',
                minWidth: 130,
                align: 'center',
                sortable: true
              },
              {
                prop: 'saleQtyYoyGrowth',
                label: '同比增长',
                minWidth: 100,
                align: 'center',
                sortable: true
              },
              {
                prop: 'saleQtyYoyGrowthRate',
                label: '同比增长率(%)',
                minWidth: 140,
                align: 'center',
                sortable: true
              }
            ],
            summary: [
              'thisPassengerFlow',
              'upperPassengerFlow',
              'passengerFlowYoyGrowth',
              'thisSaleQty',
              'upperSaleQty',
              'saleQtyYoyGrowth'
            ]
          },
          {
            title: '会员级别消费同比',
            type: 'vipLevel',
            getListApi: saleYoYList,
            // getSummaryApi: saleYoYListSummary,
            defaultBody: { summaryType: 4 },
            exportOption: {
              fastExportUrl:
                '/api/system/shop/strategy/saleYOYAnalysis/listExport',
              exportName: '会员消费同比'
            },
            //表格
            columns: [
              {
                prop: 'vipLevelNo',
                label: '级别编码',
                minWidth: 150,
                align: 'center'
              },
              {
                prop: 'vipLevelName',
                label: '级别名称',
                minWidth: 120,
                align: 'center'
              },
              {
                prop: 'thisPeriodSaleMoney',
                label: '本期销售额',
                minWidth: 110,
                align: 'center',
                sortable: true
              },
              {
                prop: 'upperPeriodSaleMoney',
                label: '上期销售额',
                minWidth: 110,
                align: 'center',
                sortable: true
              },
              {
                prop: 'saleYoyGrowth',
                label: '同比增长',
                minWidth: 110,
                align: 'center',
                sortable: true
              },
              {
                prop: 'saleYoyGrowthRate',
                label: '同比增长率(%)',
                minWidth: 130,
                align: 'center',
                sortable: true
              },
              {
                prop: 'thisSaleQty',
                label: '本期销售数量',
                minWidth: 130,
                align: 'center',
                sortable: true
              },
              {
                prop: 'upperSaleQty',
                label: '上期销售数量',
                minWidth: 130,
                align: 'center',
                sortable: true
              },
              {
                prop: 'saleQtyYoyGrowth',
                label: '同比增长',
                minWidth: 100,
                align: 'center',
                sortable: true
              },
              {
                prop: 'saleQtyYoyGrowthRate',
                label: '同比增长率(%)',
                minWidth: 140,
                align: 'center',
                sortable: true
              }
            ],
            summary: [
              'thisPeriodSaleMoney',
              'upperPeriodSaleMoney',
              'saleYoyGrowth',
              'thisSaleQty',
              'upperSaleQty',
              'saleQtyYoyGrowth'
            ]
          }
        ]
      }
    }
  },
  methods: {
    async handleEvent (type, row) {
      switch (type) {
        default:
          break
      }
    }
  }
}
</script>
