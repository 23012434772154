<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options"> </TablePage>
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import {
  listVipLoyalty,
  listVipLoyaltySummary,
} from "@/api/decision/vipReport/vipRFMAnalysis.js";

export default {
  name: "VipRFMAnalysis",
  components: { TablePage },
  data() {
    return {
      options: {
        summary: "",
        listNo: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        getListApi: listVipLoyalty,
        getSummaryApi: listVipLoyaltySummary,
        title: "会员兑换查询",
        rowKey: "",
        search: [
          {
            label: "消费日期",
            type: "datetimerange",
            model: "",
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b }),
          },
          {
            keyIndex: 0,
            format: "datetimerange",
            type: "timeRange",
            defaultValue: "day",
            data: [
              "day",
              "yesterday",
              "week",
              "lastWeek",
              "month",
              "lastMonth",
            ],
          },
          {
            type: "filters",
            tip: "会员卡号/会员名称/手机号",
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "vipNos", label: "会员卡号" },
              { filter: "vipNames", label: "会员名称" },
              { filter: "tels", label: "手机号" },
            ],
          },
          this.$select({
            key: "listShop",
            option: {
              filter: "shopIds",
              seniorSearch: true,
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择门店",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "shop" }),
                    },
                  },
                ],
              },
            },
          }),
          {
            type: "numberRange",
            label: "客单价(次)",
            filter: "startCustomerUnitPrice",
            seniorSearch: true,
            defaultValue: undefined,
            model: undefined,
            labelWidth: 80,
            filterFnc: ([one, two] = []) => ({
              startCustomerUnitPrice: one,
              endCustomerUnitPrice: two,
            }),
          },
          {
            type: "numberRange",
            label: "消费频次(日)",
            filter: "startConsumeFrequency",
            seniorSearch: true,
            defaultValue: undefined,
            model: undefined,
            labelWidth: 100,
            filterFnc: ([one, two] = []) => ({
              startConsumeFrequency: one,
              endConsumeFrequency: two,
            }),
          },
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        //表格
        columns: [
          {
            prop: "vipNo",
            label: "会员卡号",
            minWidth: 180,
            align: "center",
          },
          {
            prop: "vipName",
            label: "会员名称",
            minWidth: 150,
            align: "center",
          },
          {
            prop: "tel",
            label: "手机号",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "vipLevelName",
            label: "级别名称",
            minWidth: 150,
            align: "center",
          },
          {
            prop: "shopName",
            label: "开卡门店",
            minWidth: 150,
            align: "center",
          },
          {
            prop: "score",
            label: "积分",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "usableMoney",
            label: "余额",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "orderAllMoney",
            label: "订单总价",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "orderAverageMoney",
            label: "订单均价",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "consumeCount",
            label: "消费次数",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "lastUseTime",
            label: "最后消费日期",
            minWidth: 155,
            align: "center",
          },
          {
            prop: "notConsumptionDuration",
            label: "距今日无消费次数",
            minWidth: 155,
            align: "center",
          },
        ],
        //合计
        summary: [
          "usableFillMoney",
          "usableScore",
          "consumeCount",
          "allConsumeMoney",
          "scoreCount",
          "allScore",
          "customerUnitPrice",
          "consumeFrequency",
          "notConsumptionDuration",
        ],
      },
    };
  },
};
</script>
