<template>
  <vipViewComponent />
</template>

<script>
import vipViewComponent from '@/views/vip/report/vipView/components'
export default {
  name: 'vipView',
  components: { vipViewComponent }
}
</script>
