<template>
  <div v-loading="loading">
    <div style="text-align: right; padding: 10px">
      <el-button
        icon="el-icon-refresh-right
"
        type="primary"
        @click="getList"
        size="mini"
        >刷新</el-button
      >
    </div>
    <div class="x-bc" style="margin-bottom: 50px">
      <div id="chart-mone1" style="width: 50%; height: 530px"></div>
      <el-table
        ref="multipleTable"
        tooltip-effect="dark"
        :data="tableData1"
        border
        max-height="530"
        height="530"
        style="width: calc(50% - 30px)"
      >
        <el-table-column
          label="区间"
          align="center"
          prop="intervals"
          min-width="155"
        />
        <el-table-column
          label="人数"
          align="center"
          prop="totalQty"
          min-width="160"
        />
        <el-table-column
          label="男"
          align="center"
          prop="maleQty"
          min-width="94"
        />
        <el-table-column
          label="女"
          align="center"
          prop="femaleQty"
          min-width="110"
        />
        <el-table-column
          label="未知"
          align="center"
          prop="unknownQty"
          min-width="110"
        />
      </el-table>
    </div>
    <div class="x-bc" style="margin-bottom: 50px">
      <div id="chart-mone2" style="width: 50%; height: 530px"></div>
      <el-table
        ref="multipleTable"
        tooltip-effect="dark"
        :data="tableData2"
        border
        max-height="530"
        height="530"
        style="width: calc(50% - 30px)"
      >
        <el-table-column
          label="区间"
          align="center"
          prop="intervals"
          min-width="155"
        />
        <el-table-column
          label="人数"
          align="center"
          prop="totalQty"
          min-width="160"
        />
        <el-table-column
          label="男"
          align="center"
          prop="maleQty"
          min-width="94"
        />
        <el-table-column
          label="女"
          align="center"
          prop="femaleQty"
          min-width="110"
        />
        <el-table-column
          label="未知"
          align="center"
          prop="unknownQty"
          min-width="110"
        />
      </el-table>
    </div>
    <div class="x-bc" style="margin-bottom: 50px">
      <div id="chart-mone3" style="width: 50%; height: 530px"></div>
      <el-table
        ref="multipleTable"
        tooltip-effect="dark"
        :data="tableData3"
        border
        max-height="530"
        height="530"
        style="width: calc(50% - 30px)"
      >
        <el-table-column
          label="区间"
          align="center"
          prop="intervals"
          min-width="155"
        />
        <el-table-column
          label="人数"
          align="center"
          prop="totalQty"
          min-width="160"
        />
        <el-table-column
          label="男"
          align="center"
          prop="maleQty"
          min-width="94"
        />
        <el-table-column
          label="女"
          align="center"
          prop="femaleQty"
          min-width="110"
        />
        <el-table-column
          label="未知"
          align="center"
          prop="unknownQty"
          min-width="110"
        />
      </el-table>
    </div>
    <div class="x-bc" style="margin-bottom: 50px">
      <div id="chart-mone4" style="width: 50%; height: 530px"></div>
      <el-table
        ref="multipleTable"
        tooltip-effect="dark"
        :data="tableData4"
        border
        max-height="530"
        height="530"
        style="width: calc(50% - 30px)"
      >
        <el-table-column
          label="区间"
          align="center"
          prop="intervals"
          min-width="155"
        />
        <el-table-column
          label="人数"
          align="center"
          prop="totalQty"
          min-width="160"
        />
        <el-table-column
          label="男"
          align="center"
          prop="maleQty"
          min-width="94"
        />
        <el-table-column
          label="女"
          align="center"
          prop="femaleQty"
          min-width="110"
        />
        <el-table-column
          label="未知"
          align="center"
          prop="unknownQty"
          min-width="110"
        />
      </el-table>
    </div>
  </div>
</template>

<script>
import { vipQualityAnalysisList } from "@/api/decision/vipReport/vipQualityAnalysis"; //会员品质分析
export default {
  name: "vipQualityAnalysis",
  data() {
    return {
      tableData1: [],
      tableData2: [],
      tableData3: [],
      tableData4: [],
      myGoalChart1: undefined,
      myGoalChart2: undefined,
      myGoalChart3: undefined,
      myGoalChart4: undefined,
      loading: false,
    };
  },
  async mounted() {
    await this.getList(true);
  },
  methods: {
    async getList(bol) {
      try {
        this.loading = true;
        // 并发执行所有请求
        const promises = [
          vipQualityAnalysisList({ summaryType: 1 }),
          vipQualityAnalysisList({ summaryType: 2 }),
          vipQualityAnalysisList({ summaryType: 3 }),
          vipQualityAnalysisList({ summaryType: 4 }),
        ];

        // 等待所有请求完成，并获取结果
        const results = await Promise.all(promises);

        // 分别处理每个结果
        this.tableData1 = results[0].rows || [];
        this.initChartGoal1(bol);
        this.tableData2 = results[1].rows || [];
        this.initChartGoal2(bol);
        this.tableData3 = results[2].rows || [];
        this.initChartGoal3(bol);
        this.tableData4 = results[3].rows || [];
        this.initChartGoal4(bol);
      } catch (error) {
      } finally {
        this.loading = false;
      }
    },
    initChartGoal1(bol) {
      if (bol) {
        if (this.myGoalChart1) {
          this.myGoalChart1.dispose(); // 销毁之前的实例
        }
        const chartContainerAdd = document.getElementById("chart-mone1");
        this.myGoalChart1 = this.$echarts.init(chartContainerAdd);
      }
      //会员增长
      this.myGoalChart1.setOption({
        title: {
          text: "会员平均消费分布",
        },
        tooltip: {
          trigger: "axis", // 提示框触发类型
          axisPointer: {
            crossStyle: {
              color: "#73d13d", // 十字准星的颜色
            },
          },
          formatter: (params) => {
            return `区间:${params[0].name}<br>${params[0].marker}总人数:${params[0].data}(个)<br>${params[1].marker}男:${params[1].data}(个)<br>${params[2].marker}女:${params[2].data}(个)<br>${params[3].marker}未知:${params[3].data}(个)`; // 提示框内容格式化
          },
        },
        legend: {
          data: ["总人数", "男", "女", "未知"], // 图例名称
          itemWidth: 30, // 设置图例项的宽度，增加线的长度
          // itemHeight: 0, // 设置图例项的高度为0，隐藏中间的点
          textStyle: {
            fontSize: 14, //文本大小
            color: "#333", // 图例文本颜色
          },
        },
        grid: {
          left: "1%", // 图表左边空白
          right: "1%", // 图表右边空白
          bottom: "5%", // 图表底部空白
          containLabel: true, // 包含坐标轴的刻度标签
        },
        xAxis: [
          {
            type: "category", // x 轴类型为类目轴
            data: this.tableData1?.map((v) => v.intervals) || [], // x 轴数据
            axisTick: {
              show: false, // 不显示刻度线
            },
            axisLine: {
              lineStyle: {
                color: "#dddddd", // x 轴线颜色
              },
            },
            boundaryGap: false, // 两端空白策略，false 表示留白
            axisLabel: {
              color: "#cccccc", // x 轴文本的颜色
              fontSize: 14, // x 轴标签字体大小
            },
          },
        ],
        yAxis: [
          {
            type: "value", // y 轴类型为数值轴
            name: "个数", // y 轴名称
            nameGap: 10, // 设置标题与y轴的距离
            axisLine: {
              show: false, // 不显示坐标轴轴线
              lineStyle: {
                color: "#999999", // y 轴线颜色
              },
            },
            axisTick: {
              show: false, // 不显示刻度线
            },
            splitLine: {
              lineStyle: {
                type: "dashed", // 分隔线样式为虚线
                color: "#E8E8E8", // y 轴线颜色
              },
            },
            axisLabel: {
              // formatter: '{value} 元', // 自定义刻度线显示格式，显示数值和单位
              fontSize: 14, // y 轴标签字体大小
            },
          },
          {
            type: "value", // y 轴类型为数值轴
            name: "", // y 轴名称
            nameGap: 25, // 设置标题与y轴的距离
            position: "right", // y 轴位置
            axisLine: {
              show: false, // 不显示坐标轴轴线
              lineStyle: {
                color: "#999999", // y 轴线颜色
              },
            },
            axisTick: {
              show: false, // 不显示刻度线
            },
            splitLine: {
              lineStyle: {
                type: "dashed", // 分隔线样式为虚线
                color: "#E8E8E8", // y 轴线颜色
              },
            },
            axisLabel: {
              // formatter: '{value} 元', // 自定义刻度线显示格式，显示数值和单位
              fontSize: 14, // y 轴标签字体大小
            },
          },
          {
            show: false,
            type: "value", // y 轴类型为数值轴
            name: "", // y 轴名称
            nameGap: 25, // 设置标题与y轴的距离
            position: "right", // y 轴位置
            axisLine: {
              show: false, // 不显示坐标轴轴线
              lineStyle: {
                color: "#999999", // y 轴线颜色
              },
            },
            axisTick: {
              show: false, // 不显示刻度线
            },
            splitLine: {
              lineStyle: {
                type: "dashed", // 分隔线样式为虚线
                color: "#E8E8E8", // y 轴线颜色
              },
            },
            axisLabel: {
              // formatter: '{value} 笔', // 自定义刻度线显示格式，显示数值和单位
              fontSize: 14, // y 轴标签字体大小
            },
          },
          {
            show: false,
            type: "value", // y 轴类型为数值轴
            name: "", // y 轴名称
            nameGap: 25, // 设置标题与y轴的距离
            position: "right", // y 轴位置
            axisLine: {
              show: false, // 不显示坐标轴轴线
              lineStyle: {
                color: "#999999", // y 轴线颜色
              },
            },
            axisTick: {
              show: false, // 不显示刻度线
            },
            splitLine: {
              lineStyle: {
                type: "dashed", // 分隔线样式为虚线
                color: "#E8E8E8", // y 轴线颜色
              },
            },
            axisLabel: {
              // formatter: '{value} 笔', // 自定义刻度线显示格式，显示数值和单位
              fontSize: 14, // y 轴标签字体大小
            },
          },
        ],
        series: [
          {
            name: "总人数", // 系列名称
            type: "line", // 系列类型为折线图
            data: this.tableData1?.map((v) => v.totalQty) || [], // 系列数据
            yAxisIndex: 0, // 使用的 y 轴索引
            symbol: "circle", // 数据点图形
            symbolSize: 8, // 数据点大小
            itemStyle: {
              color: "#E87BCA", // 数据项样式
            },
            lineStyle: {
              color: "#E87BCA", // 折线样式
            },
          },
          {
            name: "男", // 系列名称
            type: "line", // 系列类型为折线图
            data: this.tableData1?.map((v) => v.maleQty) || [], // 系列数据
            yAxisIndex: 0, // 使用的 y 轴索引
            symbol: "circle", // 数据点图形
            symbolSize: 8, // 数据点大小
            itemStyle: {
              color: "#40B5FF", // 数据项样式
            },
            lineStyle: {
              color: "#40B5FF", // 折线样式
            },
          },
          {
            name: "女", // 系列名称
            type: "line", // 系列类型为折线图
            data: this.tableData1?.map((v) => v.femaleQty) || [], // 系列数据
            yAxisIndex: 1, // 使用的 y 轴索引
            symbol: "circle", // 数据点图形
            symbolSize: 8, // 数据点大小
            itemStyle: {
              color: "#FA8219", // 数据项样式
            },
            lineStyle: {
              color: "#FA8219", // 折线样式
            },
          },
          {
            name: "未知", // 系列名称
            type: "line", // 系列类型为折线图
            data: this.tableData1?.map((v) => v.unknownQty) || [], // 系列数据
            yAxisIndex: 2, // 使用的 y 轴索引
            symbol: "circle", // 数据点图形
            symbolSize: 8, // 数据点大小
            itemStyle: {
              color: "#50d873", // 数据项样式
            },
            lineStyle: {
              color: "#50d873", // 折线样式
            },
          },
        ],
      });
      if (bol) {
        //图表大小变动重新渲染，动态自适应
        window.addEventListener("resize", () => {
          this.myGoalChart1.resize();
        });
      }
    },
    initChartGoal2(bol) {
      if (bol) {
        if (this.myGoalChart2) {
          this.myGoalChart2.dispose(); // 销毁之前的实例
        }
        const chartContainerAdd2 = document.getElementById("chart-mone2");
        this.myGoalChart2 = this.$echarts.init(chartContainerAdd2);
      }
      //会员增长
      this.myGoalChart2.setOption({
        title: {
          text: "会员消费次数分布",
        },
        tooltip: {
          trigger: "axis", // 提示框触发类型
          axisPointer: {
            crossStyle: {
              color: "#73d13d", // 十字准星的颜色
            },
          },
          formatter: (params) => {
            return `区间:${params[0].name}<br>${params[0].marker}总人数:${params[0].data}(个)<br>${params[1].marker}男:${params[1].data}(个)<br>${params[2].marker}女:${params[2].data}(个)<br>${params[3].marker}未知:${params[3].data}(个)`; // 提示框内容格式化
          },
        },
        legend: {
          data: ["总人数", "男", "女", "未知"], // 图例名称
          itemWidth: 30, // 设置图例项的宽度，增加线的长度
          // itemHeight: 0, // 设置图例项的高度为0，隐藏中间的点
          textStyle: {
            fontSize: 14, //文本大小
            color: "#333", // 图例文本颜色
          },
        },
        grid: {
          left: "1%", // 图表左边空白
          right: "1%", // 图表右边空白
          bottom: "5%", // 图表底部空白
          containLabel: true, // 包含坐标轴的刻度标签
        },
        xAxis: [
          {
            type: "category", // x 轴类型为类目轴
            data: this.tableData2?.map((v) => v.intervals) || [], // x 轴数据
            axisTick: {
              show: false, // 不显示刻度线
            },
            axisLine: {
              lineStyle: {
                color: "#dddddd", // x 轴线颜色
              },
            },
            boundaryGap: false, // 两端空白策略，false 表示留白
            axisLabel: {
              color: "#cccccc", // x 轴文本的颜色
              fontSize: 14, // x 轴标签字体大小
            },
          },
        ],
        yAxis: [
          {
            type: "value", // y 轴类型为数值轴
            name: "个数", // y 轴名称
            nameGap: 10, // 设置标题与y轴的距离
            axisLine: {
              show: false, // 不显示坐标轴轴线
              lineStyle: {
                color: "#999999", // y 轴线颜色
              },
            },
            axisTick: {
              show: false, // 不显示刻度线
            },
            splitLine: {
              lineStyle: {
                type: "dashed", // 分隔线样式为虚线
                color: "#E8E8E8", // y 轴线颜色
              },
            },
            axisLabel: {
              // formatter: '{value} 元', // 自定义刻度线显示格式，显示数值和单位
              fontSize: 14, // y 轴标签字体大小
            },
          },
          {
            type: "value", // y 轴类型为数值轴
            name: "", // y 轴名称
            nameGap: 25, // 设置标题与y轴的距离
            position: "right", // y 轴位置
            axisLine: {
              show: false, // 不显示坐标轴轴线
              lineStyle: {
                color: "#999999", // y 轴线颜色
              },
            },
            axisTick: {
              show: false, // 不显示刻度线
            },
            splitLine: {
              lineStyle: {
                type: "dashed", // 分隔线样式为虚线
                color: "#E8E8E8", // y 轴线颜色
              },
            },
            axisLabel: {
              // formatter: '{value} 元', // 自定义刻度线显示格式，显示数值和单位
              fontSize: 14, // y 轴标签字体大小
            },
          },
          {
            show: false,
            type: "value", // y 轴类型为数值轴
            name: "", // y 轴名称
            nameGap: 25, // 设置标题与y轴的距离
            position: "right", // y 轴位置
            axisLine: {
              show: false, // 不显示坐标轴轴线
              lineStyle: {
                color: "#999999", // y 轴线颜色
              },
            },
            axisTick: {
              show: false, // 不显示刻度线
            },
            splitLine: {
              lineStyle: {
                type: "dashed", // 分隔线样式为虚线
                color: "#E8E8E8", // y 轴线颜色
              },
            },
            axisLabel: {
              // formatter: '{value} 笔', // 自定义刻度线显示格式，显示数值和单位
              fontSize: 14, // y 轴标签字体大小
            },
          },
          {
            show: false,
            type: "value", // y 轴类型为数值轴
            name: "", // y 轴名称
            nameGap: 25, // 设置标题与y轴的距离
            position: "right", // y 轴位置
            axisLine: {
              show: false, // 不显示坐标轴轴线
              lineStyle: {
                color: "#999999", // y 轴线颜色
              },
            },
            axisTick: {
              show: false, // 不显示刻度线
            },
            splitLine: {
              lineStyle: {
                type: "dashed", // 分隔线样式为虚线
                color: "#E8E8E8", // y 轴线颜色
              },
            },
            axisLabel: {
              // formatter: '{value} 笔', // 自定义刻度线显示格式，显示数值和单位
              fontSize: 14, // y 轴标签字体大小
            },
          },
        ],
        series: [
          {
            name: "总人数", // 系列名称
            type: "line", // 系列类型为折线图
            data: this.tableData2?.map((v) => v.totalQty) || [], // 系列数据
            yAxisIndex: 0, // 使用的 y 轴索引
            symbol: "circle", // 数据点图形
            symbolSize: 8, // 数据点大小
            itemStyle: {
              color: "#E87BCA", // 数据项样式
            },
            lineStyle: {
              color: "#E87BCA", // 折线样式
            },
          },
          {
            name: "男", // 系列名称
            type: "line", // 系列类型为折线图
            data: this.tableData2?.map((v) => v.maleQty) || [], // 系列数据
            yAxisIndex: 0, // 使用的 y 轴索引
            symbol: "circle", // 数据点图形
            symbolSize: 8, // 数据点大小
            itemStyle: {
              color: "#40B5FF", // 数据项样式
            },
            lineStyle: {
              color: "#40B5FF", // 折线样式
            },
          },
          {
            name: "女", // 系列名称
            type: "line", // 系列类型为折线图
            data: this.tableData2?.map((v) => v.femaleQty) || [], // 系列数据
            yAxisIndex: 1, // 使用的 y 轴索引
            symbol: "circle", // 数据点图形
            symbolSize: 8, // 数据点大小
            itemStyle: {
              color: "#FA8219", // 数据项样式
            },
            lineStyle: {
              color: "#FA8219", // 折线样式
            },
          },
          {
            name: "未知", // 系列名称
            type: "line", // 系列类型为折线图
            data: this.tableData2?.map((v) => v.unknownQty) || [], // 系列数据
            yAxisIndex: 2, // 使用的 y 轴索引
            symbol: "circle", // 数据点图形
            symbolSize: 8, // 数据点大小
            itemStyle: {
              color: "#50d873", // 数据项样式
            },
            lineStyle: {
              color: "#50d873", // 折线样式
            },
          },
        ],
      });
      if (bol) {
        //图表大小变动重新渲染，动态自适应
        window.addEventListener("resize", () => {
          this.myGoalChart2.resize();
        });
      }
    },
    initChartGoal3(bol) {
      if (bol) {
        if (this.myGoalChart3) {
          this.myGoalChart3.dispose(); // 销毁之前的实例
        }
        const chartContainerAdd = document.getElementById("chart-mone3");
        this.myGoalChart3 = this.$echarts.init(chartContainerAdd);
      }
      //会员增长
      this.myGoalChart3.setOption({
        title: {
          text: "会员储值余额分布",
        },
        tooltip: {
          trigger: "axis", // 提示框触发类型
          axisPointer: {
            crossStyle: {
              color: "#73d13d", // 十字准星的颜色
            },
          },
          formatter: (params) => {
            return `区间:${params[0].name}<br>${params[0].marker}总人数:${params[0].data}(个)<br>${params[1].marker}男:${params[1].data}(个)<br>${params[2].marker}女:${params[2].data}(个)<br>${params[3].marker}未知:${params[3].data}(个)`; // 提示框内容格式化
          },
        },
        legend: {
          data: ["总人数", "男", "女", "未知"], // 图例名称
          itemWidth: 30, // 设置图例项的宽度，增加线的长度
          // itemHeight: 0, // 设置图例项的高度为0，隐藏中间的点
          textStyle: {
            fontSize: 14, //文本大小
            color: "#333", // 图例文本颜色
          },
        },
        grid: {
          left: "1%", // 图表左边空白
          right: "1%", // 图表右边空白
          bottom: "5%", // 图表底部空白
          containLabel: true, // 包含坐标轴的刻度标签
        },
        xAxis: [
          {
            type: "category", // x 轴类型为类目轴
            data: this.tableData3?.map((v) => v.intervals) || [], // x 轴数据
            axisTick: {
              show: false, // 不显示刻度线
            },
            axisLine: {
              lineStyle: {
                color: "#dddddd", // x 轴线颜色
              },
            },
            boundaryGap: false, // 两端空白策略，false 表示留白
            axisLabel: {
              color: "#cccccc", // x 轴文本的颜色
              fontSize: 14, // x 轴标签字体大小
            },
          },
        ],
        yAxis: [
          {
            type: "value", // y 轴类型为数值轴
            name: "个数", // y 轴名称
            nameGap: 10, // 设置标题与y轴的距离
            axisLine: {
              show: false, // 不显示坐标轴轴线
              lineStyle: {
                color: "#999999", // y 轴线颜色
              },
            },
            axisTick: {
              show: false, // 不显示刻度线
            },
            splitLine: {
              lineStyle: {
                type: "dashed", // 分隔线样式为虚线
                color: "#E8E8E8", // y 轴线颜色
              },
            },
            axisLabel: {
              // formatter: '{value} 元', // 自定义刻度线显示格式，显示数值和单位
              fontSize: 14, // y 轴标签字体大小
            },
          },
          {
            type: "value", // y 轴类型为数值轴
            name: "", // y 轴名称
            nameGap: 25, // 设置标题与y轴的距离
            position: "right", // y 轴位置
            axisLine: {
              show: false, // 不显示坐标轴轴线
              lineStyle: {
                color: "#999999", // y 轴线颜色
              },
            },
            axisTick: {
              show: false, // 不显示刻度线
            },
            splitLine: {
              lineStyle: {
                type: "dashed", // 分隔线样式为虚线
                color: "#E8E8E8", // y 轴线颜色
              },
            },
            axisLabel: {
              // formatter: '{value} 元', // 自定义刻度线显示格式，显示数值和单位
              fontSize: 14, // y 轴标签字体大小
            },
          },
          {
            show: false,
            type: "value", // y 轴类型为数值轴
            name: "", // y 轴名称
            nameGap: 25, // 设置标题与y轴的距离
            position: "right", // y 轴位置
            axisLine: {
              show: false, // 不显示坐标轴轴线
              lineStyle: {
                color: "#999999", // y 轴线颜色
              },
            },
            axisTick: {
              show: false, // 不显示刻度线
            },
            splitLine: {
              lineStyle: {
                type: "dashed", // 分隔线样式为虚线
                color: "#E8E8E8", // y 轴线颜色
              },
            },
            axisLabel: {
              // formatter: '{value} 笔', // 自定义刻度线显示格式，显示数值和单位
              fontSize: 14, // y 轴标签字体大小
            },
          },
          {
            show: false,
            type: "value", // y 轴类型为数值轴
            name: "", // y 轴名称
            nameGap: 25, // 设置标题与y轴的距离
            position: "right", // y 轴位置
            axisLine: {
              show: false, // 不显示坐标轴轴线
              lineStyle: {
                color: "#999999", // y 轴线颜色
              },
            },
            axisTick: {
              show: false, // 不显示刻度线
            },
            splitLine: {
              lineStyle: {
                type: "dashed", // 分隔线样式为虚线
                color: "#E8E8E8", // y 轴线颜色
              },
            },
            axisLabel: {
              // formatter: '{value} 笔', // 自定义刻度线显示格式，显示数值和单位
              fontSize: 14, // y 轴标签字体大小
            },
          },
        ],
        series: [
          {
            name: "总人数", // 系列名称
            type: "line", // 系列类型为折线图
            data: this.tableData3?.map((v) => v.totalQty) || [], // 系列数据
            yAxisIndex: 0, // 使用的 y 轴索引
            symbol: "circle", // 数据点图形
            symbolSize: 8, // 数据点大小
            itemStyle: {
              color: "#E87BCA", // 数据项样式
            },
            lineStyle: {
              color: "#E87BCA", // 折线样式
            },
          },
          {
            name: "男", // 系列名称
            type: "line", // 系列类型为折线图
            data: this.tableData3?.map((v) => v.maleQty) || [], // 系列数据
            yAxisIndex: 0, // 使用的 y 轴索引
            symbol: "circle", // 数据点图形
            symbolSize: 8, // 数据点大小
            itemStyle: {
              color: "#40B5FF", // 数据项样式
            },
            lineStyle: {
              color: "#40B5FF", // 折线样式
            },
          },
          {
            name: "女", // 系列名称
            type: "line", // 系列类型为折线图
            data: this.tableData3?.map((v) => v.femaleQty) || [], // 系列数据
            yAxisIndex: 1, // 使用的 y 轴索引
            symbol: "circle", // 数据点图形
            symbolSize: 8, // 数据点大小
            itemStyle: {
              color: "#FA8219", // 数据项样式
            },
            lineStyle: {
              color: "#FA8219", // 折线样式
            },
          },
          {
            name: "未知", // 系列名称
            type: "line", // 系列类型为折线图
            data: this.tableData3?.map((v) => v.unknownQty) || [], // 系列数据
            yAxisIndex: 2, // 使用的 y 轴索引
            symbol: "circle", // 数据点图形
            symbolSize: 8, // 数据点大小
            itemStyle: {
              color: "#50d873", // 数据项样式
            },
            lineStyle: {
              color: "#50d873", // 折线样式
            },
          },
        ],
      });
      if (bol) {
        //图表大小变动重新渲染，动态自适应
        window.addEventListener("resize", () => {
          this.myGoalChart3.resize();
        });
      }
    },
    initChartGoal4(bol) {
      if (bol) {
        if (this.myGoalChart4) {
          this.myGoalChart4.dispose(); // 销毁之前的实例
        }
        const chartContainerAdd = document.getElementById("chart-mone4");
        this.myGoalChart4 = this.$echarts.init(chartContainerAdd);
      }
      //会员增长
      this.myGoalChart4.setOption({
        title: {
          text: "会员积分分布",
        },
        tooltip: {
          trigger: "axis", // 提示框触发类型
          axisPointer: {
            crossStyle: {
              color: "#73d13d", // 十字准星的颜色
            },
          },
          formatter: (params) => {
            return `区间:${params[0].name}<br>${params[0].marker}总人数:${params[0].data}(个)<br>${params[1].marker}男:${params[1].data}(个)<br>${params[2].marker}女:${params[2].data}(个)<br>${params[3].marker}未知:${params[3].data}(个)`; // 提示框内容格式化
          },
        },
        legend: {
          data: ["总人数", "男", "女", "未知"], // 图例名称
          itemWidth: 30, // 设置图例项的宽度，增加线的长度
          // itemHeight: 0, // 设置图例项的高度为0，隐藏中间的点
          textStyle: {
            fontSize: 14, //文本大小
            color: "#333", // 图例文本颜色
          },
        },
        grid: {
          left: "1%", // 图表左边空白
          right: "1%", // 图表右边空白
          bottom: "5%", // 图表底部空白
          containLabel: true, // 包含坐标轴的刻度标签
        },
        xAxis: [
          {
            type: "category", // x 轴类型为类目轴
            data: this.tableData4?.map((v) => v.intervals) || [], // x 轴数据
            axisTick: {
              show: false, // 不显示刻度线
            },
            axisLine: {
              lineStyle: {
                color: "#dddddd", // x 轴线颜色
              },
            },
            boundaryGap: false, // 两端空白策略，false 表示留白
            axisLabel: {
              color: "#cccccc", // x 轴文本的颜色
              fontSize: 14, // x 轴标签字体大小
            },
          },
        ],
        yAxis: [
          {
            type: "value", // y 轴类型为数值轴
            name: "个数", // y 轴名称
            nameGap: 10, // 设置标题与y轴的距离
            axisLine: {
              show: false, // 不显示坐标轴轴线
              lineStyle: {
                color: "#999999", // y 轴线颜色
              },
            },
            axisTick: {
              show: false, // 不显示刻度线
            },
            splitLine: {
              lineStyle: {
                type: "dashed", // 分隔线样式为虚线
                color: "#E8E8E8", // y 轴线颜色
              },
            },
            axisLabel: {
              // formatter: '{value} 元', // 自定义刻度线显示格式，显示数值和单位
              fontSize: 14, // y 轴标签字体大小
            },
          },
          {
            type: "value", // y 轴类型为数值轴
            name: "", // y 轴名称
            nameGap: 25, // 设置标题与y轴的距离
            position: "right", // y 轴位置
            axisLine: {
              show: false, // 不显示坐标轴轴线
              lineStyle: {
                color: "#999999", // y 轴线颜色
              },
            },
            axisTick: {
              show: false, // 不显示刻度线
            },
            splitLine: {
              lineStyle: {
                type: "dashed", // 分隔线样式为虚线
                color: "#E8E8E8", // y 轴线颜色
              },
            },
            axisLabel: {
              // formatter: '{value} 元', // 自定义刻度线显示格式，显示数值和单位
              fontSize: 14, // y 轴标签字体大小
            },
          },
          {
            show: false,
            type: "value", // y 轴类型为数值轴
            name: "", // y 轴名称
            nameGap: 25, // 设置标题与y轴的距离
            position: "right", // y 轴位置
            axisLine: {
              show: false, // 不显示坐标轴轴线
              lineStyle: {
                color: "#999999", // y 轴线颜色
              },
            },
            axisTick: {
              show: false, // 不显示刻度线
            },
            splitLine: {
              lineStyle: {
                type: "dashed", // 分隔线样式为虚线
                color: "#E8E8E8", // y 轴线颜色
              },
            },
            axisLabel: {
              // formatter: '{value} 笔', // 自定义刻度线显示格式，显示数值和单位
              fontSize: 14, // y 轴标签字体大小
            },
          },
          {
            show: false,
            type: "value", // y 轴类型为数值轴
            name: "", // y 轴名称
            nameGap: 25, // 设置标题与y轴的距离
            position: "right", // y 轴位置
            axisLine: {
              show: false, // 不显示坐标轴轴线
              lineStyle: {
                color: "#999999", // y 轴线颜色
              },
            },
            axisTick: {
              show: false, // 不显示刻度线
            },
            splitLine: {
              lineStyle: {
                type: "dashed", // 分隔线样式为虚线
                color: "#E8E8E8", // y 轴线颜色
              },
            },
            axisLabel: {
              // formatter: '{value} 笔', // 自定义刻度线显示格式，显示数值和单位
              fontSize: 14, // y 轴标签字体大小
            },
          },
        ],
        series: [
          {
            name: "总人数", // 系列名称
            type: "line", // 系列类型为折线图
            data: this.tableData4?.map((v) => v.totalQty) || [], // 系列数据
            yAxisIndex: 0, // 使用的 y 轴索引
            symbol: "circle", // 数据点图形
            symbolSize: 8, // 数据点大小
            itemStyle: {
              color: "#E87BCA", // 数据项样式
            },
            lineStyle: {
              color: "#E87BCA", // 折线样式
            },
          },
          {
            name: "男", // 系列名称
            type: "line", // 系列类型为折线图
            data: this.tableData4?.map((v) => v.maleQty) || [], // 系列数据
            yAxisIndex: 0, // 使用的 y 轴索引
            symbol: "circle", // 数据点图形
            symbolSize: 8, // 数据点大小
            itemStyle: {
              color: "#40B5FF", // 数据项样式
            },
            lineStyle: {
              color: "#40B5FF", // 折线样式
            },
          },
          {
            name: "女", // 系列名称
            type: "line", // 系列类型为折线图
            data: this.tableData4?.map((v) => v.femaleQty) || [], // 系列数据
            yAxisIndex: 1, // 使用的 y 轴索引
            symbol: "circle", // 数据点图形
            symbolSize: 8, // 数据点大小
            itemStyle: {
              color: "#FA8219", // 数据项样式
            },
            lineStyle: {
              color: "#FA8219", // 折线样式
            },
          },
          {
            name: "未知", // 系列名称
            type: "line", // 系列类型为折线图
            data: this.tableData4?.map((v) => v.unknownQty) || [], // 系列数据
            yAxisIndex: 2, // 使用的 y 轴索引
            symbol: "circle", // 数据点图形
            symbolSize: 8, // 数据点大小
            itemStyle: {
              color: "#50d873", // 数据项样式
            },
            lineStyle: {
              color: "#50d873", // 折线样式
            },
          },
        ],
      });
      if (bol) {
        //图表大小变动重新渲染，动态自适应
        window.addEventListener("resize", () => {
          this.myGoalChart4.resize();
        });
      }
    },
  },
};
</script>

<style></style>
