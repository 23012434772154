<template>
  <ShopComposite />
</template>

<script>
import ShopComposite from './components'
export default {
  name: 'ShopComposite',
  components: { ShopComposite }
}
</script>
