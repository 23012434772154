<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent">
    </TablePage>
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import { vipLastFillPayAnalysisList } from "@/api/decision/vipReport/vipLastFillPayAnalysis"; //会员近期充值消费分析
export default {
  name: "vipLastFillPayAnalysis",
  components: {
    TablePage,
  },
  data() {
    return {
      options: {
        listNo: true,
        loading: true,
        isRestTablecolumns: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        body: {},
        title: "会员近期充值消费分析",
        rowKey: "shopId",
        search: [
          {
            type: "radio",
            label: "",
            filter: "type",
            model: 0,
            defaultValue: 0,
            option: {
              data: [
                {
                  label: "均分区间",
                  value: 0,
                },
                {
                  label: "自选区间",
                  value: 1,
                },
              ],
            },
          },
          {
            hide: () => this.options?.search[0]?.model === 0,
            type: "equipartitionInterval",
            model: {
              begin: 0,
              end: 100,
              number: 10,
            },
            defaultValue: {
              begin: 0,
              end: 100,
              number: 10,
            },
            unitName: "天",
            filterFnc: (e) => e,
          },
          {
            hide: () => this.options?.search[0]?.model === 1,
            type: "optionalInterval",
            filter: "intervals",
            otherModel: {
              beginMoney: 0,
              endMoney: 100,
            },
            otherDefaultValue: {
              beginMoney: 0,
              endMoney: 100,
            },
            model: [
              {
                beginMoney: 0,
                endMoney: 100,
              },
            ],
            defaultValue: [
              {
                beginMoney: 0,
                endMoney: 100,
              },
            ],
            unitName: "天",
          },
          this.$select({
            key: "listShop",
            option: {
              filter: "shopIds",
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择门店",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "shop" }),
                    },
                  },
                ],
              },
            },
          }),
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          { type: "button", tip: "重置", click: "reset" },
        ],
        getListApi: vipLastFillPayAnalysisList,
        getDataKey: { list: "data" },
        columns: [
          {
            prop: "intervals",
            label: "未消费的时间长度(单位:天)",
            minWidth: 155,
          },
          {
            prop: "qty",
            label: "人数(单位:个)",
            minWidth: 150,
          },
        ],
        summary: ["qty"],
        topEchartsHeight: 500,
        getTopEchartsOption: async () => {
          await this.handleEvent("setIntervalList");
          await this.$nextTick();
          return {
            tooltip: {
              trigger: "axis", // 提示框触发类型
              axisPointer: {
                type: "line",
                lineStyle: {
                  type: "dash",
                },
              },
              formatter: (params) => {
                console.log("params", params);
                return `${params[0].marker}日期：${params[0].name}(单位:天)<br>${params[0].marker}人数:${params[0].data}(单位:个)`; // 提示框内容格式化
              },
            },
            grid: {
              left: "1%", // 图表左边空白
              right: "1%", // 图表右边空白
              bottom: "5%", // 图表底部空白
              containLabel: true, // 包含坐标轴的刻度标签
            },
            xAxis: [
              {
                type: "category", // x 轴类型为类目轴
                data: this.interval.dateStrArr, // x 轴数据
                axisTick: {
                  show: false, // 不显示刻度线
                },
                axisLine: {
                  lineStyle: {
                    color: "#dddddd", // x 轴线颜色
                  },
                },
                // boundaryGap: false, // 两端空白策略，false 表示留白
                axisLabel: {
                  color: "#cccccc", // x 轴文本的颜色
                  fontSize: 14, // x 轴标签字体大小
                },
              },
            ],
            yAxis: [
              {
                type: "value", // y 轴类型为数值轴
                name: "人数(单位:个)", // y 轴名称
                nameGap: 25, // 设置标题与y轴的距离
                axisLine: {
                  show: false, // 不显示坐标轴轴线
                  lineStyle: {
                    color: "#999999", // y 轴线颜色
                  },
                },
                axisTick: {
                  show: false, // 不显示刻度线
                },
                splitLine: {
                  lineStyle: {
                    type: "dashed", // 分隔线样式为虚线
                    color: "#E8E8E8", // y 轴线颜色
                  },
                },
                axisLabel: {
                  // formatter: "{value} 个", // 自定义刻度线显示格式，显示数值和单位
                  fontSize: 14, // y 轴标签字体大小
                  margin: 20,
                },
              },
            ],
            series: [
              {
                // name: '未消费的时间长度(单位:天)', // 系列名称
                type: "bar", // 系列类型为折线图
                data: this.interval.payMoneyArr, // 系列数据
                barWidth: "15%",
                itemStyle: {
                  color: "#4E95FF",
                },
              },
            ],
          };
        },
      },
      intervalList: {}, //图表
    };
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        case "setIntervalList":
          this.interval = {
            dateStrArr: [],
            payMoneyArr: [],
          };
          this.options.list.forEach((range, i) => {
            this.interval.dateStrArr.push(range.intervals);
            this.interval.payMoneyArr.push(range.qty);
          });
          break;
        default:
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep #table-page {
  padding: 10px 220px !important;
}
</style>
