var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
    },
    [
      _c(
        "div",
        { staticStyle: { "text-align": "right", padding: "10px" } },
        [
          _c(
            "el-button",
            {
              attrs: {
                icon: "el-icon-refresh-right\n",
                type: "primary",
                size: "mini",
              },
              on: { click: _vm.getList },
            },
            [_vm._v("刷新")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "x-bc", staticStyle: { "margin-bottom": "50px" } },
        [
          _c("div", {
            staticStyle: { width: "50%", height: "530px" },
            attrs: { id: "chart-mone1" },
          }),
          _c(
            "el-table",
            {
              ref: "multipleTable",
              staticStyle: { width: "calc(50% - 30px)" },
              attrs: {
                "tooltip-effect": "dark",
                data: _vm.tableData1,
                border: "",
                "max-height": "530",
                height: "530",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "区间",
                  align: "center",
                  prop: "intervals",
                  "min-width": "155",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "人数",
                  align: "center",
                  prop: "totalQty",
                  "min-width": "160",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "男",
                  align: "center",
                  prop: "maleQty",
                  "min-width": "94",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "女",
                  align: "center",
                  prop: "femaleQty",
                  "min-width": "110",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "未知",
                  align: "center",
                  prop: "unknownQty",
                  "min-width": "110",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "x-bc", staticStyle: { "margin-bottom": "50px" } },
        [
          _c("div", {
            staticStyle: { width: "50%", height: "530px" },
            attrs: { id: "chart-mone2" },
          }),
          _c(
            "el-table",
            {
              ref: "multipleTable",
              staticStyle: { width: "calc(50% - 30px)" },
              attrs: {
                "tooltip-effect": "dark",
                data: _vm.tableData2,
                border: "",
                "max-height": "530",
                height: "530",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "区间",
                  align: "center",
                  prop: "intervals",
                  "min-width": "155",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "人数",
                  align: "center",
                  prop: "totalQty",
                  "min-width": "160",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "男",
                  align: "center",
                  prop: "maleQty",
                  "min-width": "94",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "女",
                  align: "center",
                  prop: "femaleQty",
                  "min-width": "110",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "未知",
                  align: "center",
                  prop: "unknownQty",
                  "min-width": "110",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "x-bc", staticStyle: { "margin-bottom": "50px" } },
        [
          _c("div", {
            staticStyle: { width: "50%", height: "530px" },
            attrs: { id: "chart-mone3" },
          }),
          _c(
            "el-table",
            {
              ref: "multipleTable",
              staticStyle: { width: "calc(50% - 30px)" },
              attrs: {
                "tooltip-effect": "dark",
                data: _vm.tableData3,
                border: "",
                "max-height": "530",
                height: "530",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "区间",
                  align: "center",
                  prop: "intervals",
                  "min-width": "155",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "人数",
                  align: "center",
                  prop: "totalQty",
                  "min-width": "160",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "男",
                  align: "center",
                  prop: "maleQty",
                  "min-width": "94",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "女",
                  align: "center",
                  prop: "femaleQty",
                  "min-width": "110",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "未知",
                  align: "center",
                  prop: "unknownQty",
                  "min-width": "110",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "x-bc", staticStyle: { "margin-bottom": "50px" } },
        [
          _c("div", {
            staticStyle: { width: "50%", height: "530px" },
            attrs: { id: "chart-mone4" },
          }),
          _c(
            "el-table",
            {
              ref: "multipleTable",
              staticStyle: { width: "calc(50% - 30px)" },
              attrs: {
                "tooltip-effect": "dark",
                data: _vm.tableData4,
                border: "",
                "max-height": "530",
                height: "530",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "区间",
                  align: "center",
                  prop: "intervals",
                  "min-width": "155",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "人数",
                  align: "center",
                  prop: "totalQty",
                  "min-width": "160",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "男",
                  align: "center",
                  prop: "maleQty",
                  "min-width": "94",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "女",
                  align: "center",
                  prop: "femaleQty",
                  "min-width": "110",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "未知",
                  align: "center",
                  prop: "unknownQty",
                  "min-width": "110",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }