<template>
  <overview />
</template>

<script>
import overview from '@/views/shop/report/businessOverview/components'
export default {
  name: 'businessOverview',
  components: { overview }
}
</script>
