<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent">
    </TablePage>
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import moment from "moment";
import {
  shopGoodsDailyTotalList,
  shopGoodsDailyTotalListSummary,
} from "@/api/decision/businessReport/shopGoodsDailyTotal"; //门店商品日分析
export default {
  name: "shopGoodsDailyTotal",
  components: {
    TablePage,
  },
  data() {
    return {
      options: {
        listNo: true,
        loading: true,
        isRestTablecolumns: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        body: {},
        title: "门店商品日分析",
        rowKey: "shopId",
        search: [
          {
            label: "日期",
            type: "month",
            filter: "month",
            model: moment().format("YYYY-MM"),
            defaultValue: moment().format("YYYY-MM"),
            filterFnc: (a) => ({ billDate: a + "-01" + " 00:00:00" }),
          },
          {
            type: "filters",
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "shopNos", label: "门店编码" },
              { filter: "shopNames", label: "门店名称" },
              { filter: "categoryNos", label: "类别编码" },
              { filter: "categoryNames", label: "类别名称" },
              { filter: "goodsNos", label: "商品编码" },
              { filter: "goodsNames", label: "商品名称" },
            ],
          },
          this.$select({
            key: "listShop",
            option: {
              filter: "shopIds",
              seniorSearch: true,
              hide: () => this.options?.tabColumnType == "shop",
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择门店",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "shop" }),
                    },
                  },
                ],
              },
            },
          }),
          this.$select({
            key: "listCategory",
            option: {
              filter: "categoryIds",
              seniorSearch: true,
              hide: () => this.options?.tabColumnType == "category",
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择商品类别",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "category" }),
                    },
                  },
                ],
              },
            },
          }),
          this.$select({
            key: "listGoods",
            option: {
              filter: "goodsIds",
              seniorSearch: true,
              hide: () => this.options?.tabColumnType == "goods",
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择商品",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "goods" }),
                    },
                  },
                ],
              },
            },
          }),
          {
            type: "textTip",
            tip: "数据统计截止到昨天",
          },
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        tabColumnType: "date",
        tabsColumns: [
          {
            title: "日销售分析",
            type: "date",
            getListApi: shopGoodsDailyTotalList,
            getSummaryApi: shopGoodsDailyTotalListSummary,
            defaultBody: { summaryType: 0 },
            exportOption: {
              fastExportUrl:
                "/api/system/shop/strategy/shopGoodsDayAnalysis/listExport",
              exportName: "日销售分析",
            },
            columns: [
              {
                prop: "date",
                label: "日期",
                minWidth: 150,
                getColumns: () =>
                  this.options.list?.[0]?.shopGoodsDayDetails?.map?.((x) => ({
                    ...x,
                    label: x.day + "号",
                    prop: `shopGoodsDayDetails.${x.day}`,
                    minWidth: 110,
                    summary: true,
                    formatter: (v, row) => {
                      return (
                        row.shopGoodsDayDetails?.find((y) => y.day === x.day)
                          ?.unitMoney || 0
                      );
                    },
                  })) || [],
              },
            ],
          },
          {
            title: "门店日销售分析",
            type: "shop",
            getListApi: shopGoodsDailyTotalList,
            getSummaryApi: shopGoodsDailyTotalListSummary,
            defaultBody: { summaryType: 1 },
            exportOption: {
              fastExportUrl:
                "/api/system/shop/strategy/shopGoodsDayAnalysis/listExport",
              exportName: "门店日销售分析",
            },
            columns: [
              {
                prop: "shopNo",
                label: "门店编码",
                minWidth: 150,
              },
              {
                prop: "shopName",
                label: "门店名称",
                minWidth: 150,
              },
              {
                prop: "date",
                label: "日期",
                minWidth: 150,
                getColumns: () =>
                  this.options.list?.[0]?.shopGoodsDayDetails?.map?.((x) => ({
                    ...x,
                    label: x.day + "号",
                    prop: `shopGoodsDayDetails.${x.day}`,
                    minWidth: 110,
                    summary: true,
                    formatter: (v, row) => {
                      return (
                        row.shopGoodsDayDetails?.find((y) => y.day === x.day)
                          ?.unitMoney || 0
                      );
                    },
                  })) || [],
              },
            ],
          },
          {
            title: "类别日销售分析",
            type: "category",
            getListApi: shopGoodsDailyTotalList,
            getSummaryApi: shopGoodsDailyTotalListSummary,
            defaultBody: { summaryType: 2 },
            exportOption: {
              fastExportUrl:
                "/api/system/shop/strategy/shopGoodsDayAnalysis/listExport",
              exportName: "类别日销售分析",
            },
            columns: [
              {
                prop: "categoryNo",
                label: "类别编码",
                minWidth: 150,
              },
              {
                prop: "categoryName",
                label: "类别名称",
                minWidth: 150,
              },
              {
                prop: "date",
                label: "日期",
                minWidth: 150,
                getColumns: () =>
                  this.options.list?.[0]?.shopGoodsDayDetails?.map?.((x) => ({
                    ...x,
                    label: x.day + "号",
                    prop: `shopGoodsDayDetails.${x.day}`,
                    minWidth: 110,
                    summary: true,
                    formatter: (v, row) => {
                      return (
                        row.shopGoodsDayDetails?.find((y) => y.day === x.day)
                          ?.unitMoney || 0
                      );
                    },
                  })) || [],
              },
            ],
          },
          {
            title: "商品日销售分析",
            type: "goods",
            getListApi: shopGoodsDailyTotalList,
            getSummaryApi: shopGoodsDailyTotalListSummary,
            defaultBody: { summaryType: 3 },
            exportOption: {
              fastExportUrl:
                "/api/system/shop/strategy/shopGoodsDayAnalysis/listExport",
              exportName: "商品日销售分析",
            },
            columns: [
              {
                prop: "goodsNo",
                label: "商品编码",
                minWidth: 150,
              },
              {
                prop: "goodsName",
                label: "商品名称",
                minWidth: 150,
              },
              {
                prop: "date",
                label: "日期",
                minWidth: 150,
                getColumns: () =>
                  this.options.list?.[0]?.shopGoodsDayDetails?.map?.((x) => ({
                    ...x,
                    label: x.day + "号",
                    prop: `shopGoodsDayDetails.${x.day}`,
                    minWidth: 110,
                    summary: true,
                    formatter: (v, row) => {
                      return (
                        row.shopGoodsDayDetails?.find((y) => y.day === x.day)
                          ?.unitMoney || 0
                      );
                    },
                  })) || [],
              },
            ],
          },
        ],
      },
    };
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        default:
          break;
      }
    },
  },
};
</script>
