<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options"> </TablePage>
  </div>
</template>
<script>
import {
  franchiseShopCompositeList,
  franchiseShopCompositeListSummary
} from '@/api/joinClient/report/saleGrossProfit' // 加盟门店综合分析
import TablePage from '@/components/tablePage' //表格页面组件
import {
  shopCompositeList,
  shopCompositeListSummary
} from '@/api/decision/saleQueryReport/shopComposite' // 门店综合分析
export default {
  name: 'shopCompositeCom',
  components: {
    TablePage
  },
  props: {
    shopType: {
      type: Number,
      default: 1
    },
  },
  data() {
    return {
      options: {
        loading: false,
        pagination: {
          total: 0,
          page: 1,
          size: 15
        },
        title: '门店综合分析',
        getListApi: this.shopType == 2 ? franchiseShopCompositeList : shopCompositeList,
        getSummaryApi: this.shopType == 2 ? franchiseShopCompositeListSummary : shopCompositeListSummary,
        listNo: true, // 序号
        exportOption: {
          fastExportUrl:
            this.shopType == 2 ? '/api/system/franchise/shop/strategy/shopComprehensiveAnalysis/ranking/listExport' : '/api/system/shop/strategy/shopComprehensiveAnalysis/ranking/listExport',
          exportName: this.shopType == 2 ? '加盟商毛利' : '门店综合分析'
        },
        //搜索
        search: [
          {
            label: '单据日期',
            type: 'datetimerange',
            model: '',
            noReset: true,
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b })
          },
          {
            keyIndex: 0,
            format: 'datetimerange',
            type: 'timeRange',
            defaultValue: 'day',
            data: ['day', 'yesterday', 'week', 'lastWeek', 'month', 'lastMonth']
          },
          {
            type: 'filters',
            tip: '门店编号/门店名称',
            model: '',
            filters: [
              { filter: 'query', label: '全部' },
              { filter: 'shopNos', label: '门店编号' },
              { filter: 'shopNames', label: '门店名称' }
            ]
          },
          this.$select({
            key: 'listShop',
            option: {
              filter: 'shopIds',
              seniorSearch: true,
              option: {
                multiple: true,
                buttons: [
                  {
                    type: 'more',
                    option: {
                      title: '选择门店',
                      width: 1250,
                      type: 'TreeAndTable',
                      formData: this.$dialog({ key: 'shop' })
                    }
                  }
                ]
              }
            }
          }),
          { type: 'button', tip: '查询', btnType: 'primary', click: 'search' },
          {
            type: 'button',
            tip: '高级查询',
            btnType: 'primary',
            click: 'seniorSearch'
          },
          { type: 'button', tip: '重置', click: 'reset' }
        ],
        //表格
        columns: [
          {
            prop: 'shopNo',
            label: '门店编号',
            minWidth: 150,
            align: 'center'
          },
          {
            prop: 'shopName',
            label: '门店名称',
            minWidth: 180,
            align: 'center'
          },
          {
            prop: 'saleMoney',
            label: '销售金额',
            minWidth: 120,
            align: 'center',
            sortable: true
          },
          {
            prop: 'saleCostMoney',
            label: '销售成本',
            minWidth: 120,
            align: 'center',
            sortable: true
          },
          {
            prop: 'gainRate',
            label: '销售毛利率（%）',
            minWidth: 150,
            align: 'center',
            sortable: true
          },
          {
            prop: 'totalTradeQty',
            label: '交易笔数',
            minWidth: 120,
            align: 'center',
            sortable: true
          },
          {
            prop: 'refundMoney',
            label: '退货金额',
            minWidth: 120,
            align: 'center',
            sortable: true
          },
          {
            prop: 'refundQty',
            label: '退货笔数',
            minWidth: 120,
            align: 'center',
            sortable: true
          },
          {
            prop: 'penUnitPrice',
            label: '笔单价',
            minWidth: 120,
            align: 'center',
            sortable: true
          },
          {
            prop: 'vipScoreCoupon',
            label: '会员消费额（含积分，会员优惠）',
            minWidth: 240,
            align: 'center',
            sortable: true
          },
          {
            prop: 'vipTradeQty',
            label: '会员交易笔数',
            minWidth: 130,
            align: 'center',
            sortable: true
          },
          {
            prop: 'vipCustomerUnitPrice',
            label: '会员客单价',
            minWidth: 120,
            align: 'center',
            sortable: true
          },
          {
            prop: 'consumptionRange',
            label: '会员消费占比（%）',
            minWidth: 170,
            align: 'center',
            sortable: true
          },
          {
            prop: 'newVipStoredValue',
            label: '新增会员储值',
            minWidth: 130,
            align: 'center',
            sortable: true
          }
        ],
        summary: [
          'saleMoney',
          'saleCostMoney',
          'totalTradeQty',
          'refundMoney',
          'refundQty',
          'vipScoreCoupon',
          'vipTradeQty',
          'newVipStoredValue'
        ]
      }
    }
  }
}
</script>
